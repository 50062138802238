var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('video',{staticClass:"fullscreen",attrs:{"src":"https://connexima.s3-us-west-1.amazonaws.com/documents/dZSPu8hMahWd8drYqNpr6X.mp4","muted":"","autoplay":"","loop":""},domProps:{"muted":true}}),_c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"title"},[_vm._v("Welcome")]),_c('div',{staticClass:"sub-title"},[_vm._v("Sign in to Connexima storefronts.")]),_c('div',{staticClass:"form"},[_c('Form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('div',{staticClass:"role"},[_c('div',{class:[
              'attendee',
              'flex-center',
              { active: 'attendee' == _vm.form.role }
            ],on:{"click":function($event){return _vm.changeRole('attendee')}}},[_vm._v(" Buyer ")]),_c('div',{class:[
              'exhibitor',
              'flex-center',
              { active: 'exhibitor' == _vm.form.role }
            ],on:{"click":function($event){return _vm.changeRole('exhibitor')}}},[_vm._v(" Seller ")])]),_c('div',{staticClass:"email"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{staticClass:"password"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}})]),_c('div',{staticClass:"submit"},[_c('Button',{staticStyle:{"font-size":"18px"},attrs:{"loading":_vm.loading,"long":""},on:{"click":_vm.login}},[_vm._v("Sign In")])],1)])],1),_c('router-link',{staticClass:"forgot",attrs:{"to":"/user-action/forgot-password"}},[_vm._v(" I forgot my password ")]),_c('div',[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Not a member?")]),_c('router-link',{staticClass:"tip",attrs:{"to":"/user-action/register"}},[_vm._v(" Register here ")])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.connexima.com","target":"_blank","rel":"noopener noreferrer"}},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/logo-light.png"),"alt":""}})])])}]

export { render, staticRenderFns }