<template>
  <div class="container">
    <video
      class="fullscreen"
      src="https://connexima.s3-us-west-1.amazonaws.com/documents/dZSPu8hMahWd8drYqNpr6X.mp4"
      muted
      autoplay
      loop
    ></video>
    <div class="box">
      <a
        href="https://www.connexima.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="logo">
          <img class="logo-img" src="@/assets/logo-light.png" alt="" />
        </div>
      </a>
      <div class="title">Welcome</div>
      <div class="sub-title">Sign in to Connexima storefronts.</div>

      <div class="form">
        <Form ref="form" :model="form" :rules="rules">
          <div class="role">
            <div
              :class="['attendee', { active: 'attendee' == form.role }]"
              @click="changeRole('attendee')"
            >
              Buyer
            </div>
            <div
              :class="['exhibitor', { active: 'exhibitor' == form.role }]"
              @click="changeRole('exhibitor')"
            >
              Seller
            </div>
          </div>
          <div class="email">
            <input type="text" v-model="form.email" placeholder="Email" />
          </div>
          <div class="password">
            <input
              type="password"
              v-model="form.password"
              placeholder="Password"
            />
          </div>
          <div class="submit">
            <Button
              :loading="loading"
              long
              @click="login"
              style="font-size: 18px"
              >Sign In</Button
            >
          </div>
        </Form>
      </div>

      <router-link to="/user-action/forgot-password" class="forgot">
        I forgot my password
      </router-link>

      <div>
        <span style="font-size: 20px">Not a member?</span>

        <router-link to="/user-action/register" class="tip">
          Register here
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    this.$router.push("/index?login=true");
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      otherQuery: {},
      redirect: undefined,
      loading: false,

      form: {
        // email: "ouzz@test.com",
        // password: "123456"
        email: null,
        password: null,
        role: "attendee"
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please fill in the email",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password.",
            trigger: "blur"
          }
        ]
      },
      role: "attendee"
    };
  },

  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },

  methods: {
    changeRole(role) {
      this.form.role = role;
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },

    login() {
      const {
        form: { email, password, role }
      } = this;

      this.loading = true;
      this.$store
        .dispatch("user/login", {
          email,
          password,
          role
        })
        .then(res => {
          this.$store.dispatch("chat/login");
          console.info("login chat");

          if (res.user.company.role === "exhibitor") {
            this.$router.push({
              // path: "/company-profile/detail",
              path: "/home",
              query: this.otherQuery
            });
          } else {
            this.$router.push({
              path: this.redirect, // this.redirect ||
              query: this.otherQuery
            });
          }
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.login();
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  background: #404040;
  .fullscreen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }

  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 50px;
    background: #333;
    border-radius: 10px;
    color: #fff;
    .logo {
      width: 180px;
      height: 31px;
      border-radius: 6px;
      margin-bottom: 20px;
      .logo-img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .title {
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
    }
    .sub-title {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
    .form {
      width: 100%;
      .input {
        // background: red;
        /deep/ input {
          background: rgba(153, 153, 153, 0.28);
          border-color: transparent;
          color: #fff;
        }
      }
    }
    .tip {
      font-size: 20px;
      margin-bottom: 4px;
      font-weight: 500;
      color: #ef7f21;
    }
    .forgot {
      font-size: 20px;
      margin-bottom: 4px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.role {
  height: 44px;
  border-radius: 5px;
  display: flex;
  margin: 27px 0 30px 0;
  div {
    width: 200px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .attendee {
  }
  .exhibitor {
    html *,
    body * {
      font-family: Brandon Grotesqu;
    }
  }
  .active {
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
  }
}
.email {
  width: 400px;
  height: 44px;
  background: #f4f4f4;
  border-radius: 5px;
  input {
    width: 100%;
    height: 100%;
    font-size: 18px;
    padding-left: 5px;
    outline: none;
    border-radius: 5px;
  }
  margin-bottom: 30px;
}
.password {
  width: 400px;
  height: 44px;
  background: #f4f4f4;
  border-radius: 5px;
  input {
    width: 100%;
    height: 100%;
    font-size: 17px;
    padding-left: 5px;
    outline: none;
    border-radius: 5px;
  }
  margin-bottom: 30px;
}
.submit {
  margin-bottom: 20px;
  button {
    width: 400px;
    height: 44px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    /*padding-top:10px;*/
    cursor: pointer;
    border: none;
  }
}
</style>
<style lang="less">
.ivu-btn > span {
  font-size: 20px;
}
</style>
